import { LoginProfileEvent } from '@/auth/authentication'
import handleError from '@/lib/handle-error'
import { useApi } from '@/provider'
import { pstring } from '@cp949/pjs'
import { yupResolver } from '@hookform/resolvers/yup'
import { UserProfile } from '@local/domain'
import {
  CustomDialog,
  PasswordTextField,
  isEnterKeyEvent,
  isEnterOrTabKeyEvent,
  requestFocusSelector,
} from '@local/ui'
import Close from '@mui/icons-material/Close'
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  SxProps,
  TextField,
  alpha,
} from '@mui/material'
import { useCallback, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { LoginFormValues, loginFormSchema } from './login-form-schema'

const rootSx: SxProps = {}

export type LoginFormDialogProps = {
  sx?: SxProps
  className?: string
  open: boolean
  onClose: () => void
}

export default function LoginFormDialog(props: LoginFormDialogProps) {
  const { sx, className, open, onClose } = props
  const rootRef = useRef<HTMLElement>()
  const api = useApi()
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const formConfig = useForm<LoginFormValues>({
    resolver: yupResolver(loginFormSchema),
    mode: 'all',
    reValidateMode: 'onChange',
    shouldFocusError: true,
  })

  const focus = useCallback((selector: string) => {
    requestFocusSelector(rootRef.current, selector, 0)
  }, [])

  const handleCloseDialog = () => {
    onClose()
  }

  const handleLoginSuccess = (profile: UserProfile) => {
    LoginProfileEvent.send(profile)
    onClose()
  }
  // 서버 로그인 실행
  const doLogin = useCallback(
    async (lgonId: string, passwd: string): Promise<UserProfile | 'needPwdChange' | null> => {
      setLoading(true)
      try {
        const { profile, needPwdChange } = await api.auth.signIn({
          lgonId,
          passwd,
        })

        if (needPwdChange) {
          toast.warn(`비밀번호 재설정이 필요합니다`)
          return 'needPwdChange'
        }
        toast.success('로그인 되었습니다', {
          autoClose: 1500,
          position: 'top-center',
        })
        LoginProfileEvent.send(profile)
        return profile
      } catch (err) {
        handleError(err)
      } finally {
        setLoading(false)
      }
      return null
    },
    [api],
  )

  const onSubmit = (data: LoginFormValues) => {
    const lgonId = pstring.trim(data.lgonId)
    const passwd = pstring.trim(data.passwd)

    doLogin(lgonId, passwd).then((result) => {
      if (result === 'needPwdChange') {
        // routerPush('/pwd-must-change')
      } else if (!!result) {
        handleLoginSuccess(result)
      }
    })
  }

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = formConfig

  const canSubmit = isValid && !isSubmitting && !loading
  const canEdit = !isSubmitting && !loading
  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      className="LoginFormDialog-root"
      fullWidth
      maxWidth="xs"
      sx={{
        whiteSpace: 'nowrap',
        '& .MuiPaper-root': {
          borderRadius: '15px',
          py: 2,
        },
      }}
    >
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          <Box></Box>
          <Box
            component="img"
            src="/images/clover-text-logo.png"
            sx={{ height: 40, alignSelf: 'center' }}
          />
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent ref={rootRef}>
        <Stack direction="row" mt={3}>
          <Stack
            flex={3}
            direction="column"
            alignItems="center"
            spacing={3}
            sx={{ ml: 0.5, mb: 4, mt: 1 }}
          >
            <TextField
              {...register('lgonId')}
              fullWidth
              label="로그인"
              disabled={!canEdit}
              error={!!errors.lgonId}
              helperText={errors.lgonId?.message}
              onKeyDown={(e) => {
                if (isEnterOrTabKeyEvent(e)) {
                  const value = (e.target as HTMLInputElement).value
                  if (pstring.isNotBlank(value)) {
                    e.preventDefault()
                    focus('input[name=passwd]')
                  }
                }
              }}
            />
            <PasswordTextField
              {...register('passwd')}
              fullWidth
              label="비밀번호"
              placeholder="비밀번호 입력해주세요"
              error={!!errors.passwd}
              helperText={errors.passwd?.message}
              disabled={!canEdit}
              onKeyDown={(e) => {
                if (isEnterKeyEvent(e)) {
                  const value = (e.target as HTMLInputElement).value
                  if (pstring.isNotBlank(value)) {
                    e.preventDefault()
                    focus('.LoginFormDialog-submitBtn')
                  }
                }
              }}
            />

            <Box sx={{ pt: 2 }}>
              <Button
                className="LoginFormDialog-submitBtn"
                disabled={!canSubmit}
                onClick={handleSubmit(onSubmit)}
                variant="contained"
                sx={{
                  '&:hover': { backgroundColor: alpha('#303841', 0.8) },
                  py: 1,
                  px: 8,
                  borderRadius: '50px',
                  backgroundColor: '#303841',
                  height: '100%',
                  color: '#fff',
                  em: {
                    fontSize: '1.2rem',
                    fontStyle: 'normal',
                  },
                }}
              >
                <em>LogIn</em>
              </Button>
            </Box>
          </Stack>
        </Stack>
      </DialogContent>
    </CustomDialog>
  )
}
