import { ApiHelper, ApiHelperWithData, BaseRequest, splitParams } from '@clover/js-api-common'
import { NewUser, NewUserSimple, PagerData, Pds, PdsSimple, UploadedFile } from '../model'

/**
 * 입사자 API
 */
export class NewUserApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * NewUser 게시물 등록
   */
  create = (
    params: {
      title: string
      name: string
      emplAt: string
      substance: string
      fileIds: string[]
    } & BaseRequest,
  ): Promise<{ newUser: NewUserSimple }> => {
    const url = '/api/new-user/create'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * NewUser 수정
   */
  update = async (
    params: {
      newUserId: number
      title: string
      name: string
      emplAt: string
      substance?: string
      fileIds: string[]
    } & BaseRequest,
  ): Promise<{ newUser: NewUser }> => {
    const url = '/api/new-user/update'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * NewUser 목록 조회 - 페이징
   */
  list = (
    params: {
      title?: string
      rowsPerPage: number
      pageNumber: number
    } & BaseRequest,
  ): Promise<{ pagerData: PagerData<NewUserSimple> }> => {
    const url = '/api/new-user/list'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * NewUser 정보 조회
   */
  info = (
    params: {
      newUserId: number
    } & BaseRequest,
  ): Promise<{ newUser: NewUser }> => {
    const [data, extra] = splitParams(params)
    const url = `/api/new-user/info/${data.newUserId}`
    return this.withData.post(url, undefined, extra)
  }

  /**
   * NewUser 삭제
   */
  delete = async (
    params: {
      newUserId: number
    } & BaseRequest,
  ): Promise<void> => {
    const [data, extra] = splitParams(params)
    const url = `/api/new-user/delete/${data.newUserId}`
    await this.helper.post(url, undefined, extra)
  }

  /**
   * 파일명 변경
   */
  renameFile = (
    params: {
      fileId: string
      fileName: string
    } & BaseRequest,
  ): Promise<{ file: UploadedFile }> => {
    const [data, extra] = splitParams(params)
    const url = `/api/new-user/update-file-name/${data.fileId}`

    return this.withData.post(url, { fileName: data.fileName }, extra)
  }

  /**
   * 파일 업로드
   */
  uploadTempFile = (
    params: {
      file: Blob
      fileName: string
    } & BaseRequest,
  ): Promise<{ file: UploadedFile }> => {
    const url = '/api/new-user/upload'
    const [data, extra] = splitParams(params)
    const formData = new FormData()
    formData.append('file', data.file, data.fileName)
    return this.withData.postMultipart(url, formData, extra)
  }
}
