import { Box, TextField, TextFieldProps } from '@mui/material'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import type {} from '@mui/x-date-pickers/themeAugmentation'
import clsx from 'clsx'
import koDateLocale from 'date-fns/locale/ko'
import React, { useState } from 'react'
import { flatSx } from './../../lib/sx-props'
import { popperProps, rootSx } from './style'
const DATE_LOCALE = koDateLocale

type Props = {
  readonly?: boolean
  fulllWidth?: boolean
  newDateSubmit: (date: Date) => void
} & TextFieldProps

export const CLDocDatePicker = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    newDateSubmit,
    sx,
    className,
    disabled,
    fullWidth = false,
    readonly,
    ...restProps
  } = props

  const initialDate = new Date()
  const [date, setDate] = useState<Date>(initialDate)

  return (
    <Box sx={flatSx(rootSx, sx)} className={clsx('CLDocDatePicker-root', className)}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={DATE_LOCALE}>
        <DesktopDatePicker
          readOnly={readonly}
          PopperProps={popperProps}
          className="CLDocDatePicker-picker"
          value={date}
          onChange={(newValue) => {
            if (newValue) setDate(newValue)
            newDateSubmit(date)
          }}
          renderInput={(params) => (
            <TextField
              size="small"
              margin="none"
              disabled={disabled}
              fullWidth={fullWidth}
              {...restProps}
              sx={
                disabled
                  ? {
                      '& .MuiInputBase-root': {
                        backgroundColor: '#eee',
                      },
                    }
                  : {}
              }
              {...params}
            />
          )}
        />
      </LocalizationProvider>
    </Box>
  )
})

CLDocDatePicker.displayName = 'CLDocDatePicker'
