import LoginDialogOpenEventHandler from '@/auth/LoginDialogOpenEventHandler'
import LoginProfileLoader from '@/auth/LoginProfileLoader'
import CustomThemeWrapper from '@/components/CustomThemeWrapper'
import createEmotionCache from '@/createEmotionCache'
import { LightboxImageViewEventHandler } from '@/custom-events'
import StoreProvider from '@/store/StoreProvider'
import theme from '@/theme'
import { notoSansKR } from '@/theme/fonts'
import { CacheProvider, EmotionCache } from '@emotion/react'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import * as timeago from 'timeago.js'
import ko from 'timeago.js/lib/lang/ko'

timeago.register('ko', ko)

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache
}

export default function MyApp(props: MyAppProps) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <style jsx global>{`
          html {
            font-family: ${notoSansKR.style.fontFamily};
          }
        `}</style>
      </Head>
      <StoreProvider rootStoreInitialState={undefined}>
        <ThemeProvider theme={theme}>
          <CustomThemeWrapper>
            <CssBaseline />
            <Component {...pageProps} />
            <LightboxImageViewEventHandler />
            <LoginProfileLoader />
            <ToastContainer
              position="top-center"
              hideProgressBar
              autoClose={2000}
              pauseOnHover
              draggable
            />
            <LoginDialogOpenEventHandler />
          </CustomThemeWrapper>
        </ThemeProvider>
      </StoreProvider>
    </CacheProvider>
  )
}
