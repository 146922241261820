import { Box, Checkbox, CheckboxProps, SxProps } from '@mui/material'
import clsx from 'clsx'
import { flatSx } from './../lib/sx-props'

type Props = {} & CheckboxProps

const rootSx: SxProps = {
  '& .MuiButtonBase-root': {
    ml: 1,
    width: 15,
    height: 15,
  },
}

export function CLStyledTableCheckBox(props: Props) {
  const { sx, className, ...rest } = props

  return (
    <Box sx={flatSx(rootSx, sx)} className={clsx('CLStyledTableCheckBox-root', className)}>
      <Checkbox
        {...rest}
        size="small" //
      />
    </Box>
  )
}
