import { ApiHelper, ApiHelperWithData, BaseRequest, splitParams } from '@clover/js-api-common'
import { PagerData, Project } from '../model'

/**
 * 프로젝트 리스트(Project) API
 */
export class ProjectApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * Project 게시물 목록 조회 - 페이징
   */
  list = (
    params: {
      title?: string
      rowsPerPage: number
      pageNumber: number
    } & BaseRequest,
  ): Promise<{ pagerData: PagerData<Project> }> => {
    const url = '/api/project/list'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * Project 게시물 정보 단건 조회
   */
  info = (
    params: {
      projectId: number
    } & BaseRequest,
  ): Promise<{ project: Project }> => {
    const url = '/api/project/info'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * Project 게시물 정보 등록
   */

  create = (
    params: {
      category?: string
      title?: string
      period?: number
      money?: number
      place?: string
      skills?: string
    } & BaseRequest,
  ): Promise<{ project: Project }> => {
    const url = '/api/project/create'
    return this.withData.postJson(url, ...splitParams(params))
  }
}
