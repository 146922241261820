import { Box, FormControlLabel, FormControlLabelProps, SxProps } from '@mui/material'
import clsx from 'clsx'
import React from 'react'
import { flatSx } from './../lib/sx-props'

type Props = {
  label: string
  readOnly?: boolean
} & FormControlLabelProps

const rootSx: SxProps = {
  '& .MuiFormControlLabel-root': {
    ml: '-10px',
  },
  '& .MuiFormControlLabel-label': {
    fontSize: '0.8rem',
    ml: '-5px',
  },
  '& .MuiSvgIcon-root': {
    fontSize: '0.9rem',
  },
}

export const CLStyledFormControlLabel = React.forwardRef<HTMLInputElement, Props>(
  (props: Props, ref) => {
    const { sx, readOnly, control, label, className, ...rest } = props

    return (
      <Box sx={flatSx(rootSx, sx)} className={clsx('CLStyledFormControlLabel-root', className)}>
        <FormControlLabel
          ref={ref}
          {...rest}
          disabled={readOnly}
          sx={
            readOnly
              ? {
                  '& .MuiInputBase-root': {
                    backgroundColor: '#eee',
                    '& .MuiInputBase-input': {
                      WebkitTextFillColor: '#666',
                    },
                  },
                }
              : {}
          }
          control={control}
          label={label}
        />
      </Box>
    )
  },
)

CLStyledFormControlLabel.displayName = 'CLStyledFormControlLabel'
