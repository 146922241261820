import { ApiHelper, ApiHelperWithData, BaseRequest, splitParams } from '@clover/js-api-common'
import { CLAuditLogKindKey, CLLogLevelKey } from '../enum-types'
import { AppLog, AuditLog, CLJobLog, PagerData } from '../model'

/**
 * clover framework 시스템 로그 API
 * 세 종류의 로그가 있다.
 * JobLog, AuditLog, AppLog
 */
export class CLSystemLogApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * Job 로그 목록 조회 - 페이징
   */
  jobLogList = (
    params: {
      pageNumber: number
      rowsPerPage: number
      jobId?: string
      logYyyymmdd?: string
      logLevels: CLLogLevelKey[]
    } & BaseRequest,
  ): Promise<{
    pagerData: PagerData<CLJobLog>
  }> => {
    const url = '/p/cloverframework/system-log/job-log-list'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * App 로그 목록 조회 - 페이징
   */
  appLogList = (
    params: {
      pageNumber: number
      rowsPerPage: number
      logLevels?: CLLogLevelKey[]
      userName?: string
      title?: string
      logTag?: string
      logYyyymmdd?: string
    } & BaseRequest,
  ): Promise<{
    pagerData: PagerData<AppLog>
  }> => {
    const url = '/p/cloverframework/system-log/app-log-list'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * Audit 로그 목록 조회 - 페이징
   */
  auditLogList = (
    params: {
      pageNumber: number
      rowsPerPage: number
      logLevels?: CLLogLevelKey[]
      logKind?: CLAuditLogKindKey
      title?: string
      jobId?: string
      pageId?: string
      operatorName?: string
      logTag?: string
      clientIp?: string
      logYyyymmdd?: string
    } & BaseRequest,
  ): Promise<{
    pagerData: PagerData<AuditLog>
  }> => {
    const url = '/p/cloverframework/system-log/audit-log-list'
    return this.withData.postJson(url, ...splitParams(params))
  }
}
