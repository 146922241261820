import mitt, { Emitter } from 'mitt'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useSessionStorage } from 'react-use'

type SetStateFn<T> = (value: T | undefined) => void

type GetFn<T> = () => T

const emitters: Record<string, Emitter<any>> = {}

const getOrCreateEmitter = (key: string): Emitter<any> => {
  const instance = emitters[key]
  if (instance) {
    return instance
  }
  emitters[key] = mitt()
  return emitters[key]
}

function getDefaultValue<T>(value: T | GetFn<T>): T {
  if (value instanceof Function) {
    return value()
  }
  return value
}

export function useObservableSessionStorage<T>(
  key: string,
  initialValue: T | GetFn<T>,
): [T, SetStateFn<T | undefined>] {
  const defaultValueRef = useRef<T>(getDefaultValue(initialValue))

  const [value, setValue] = useSessionStorage<T | undefined>(key, defaultValueRef.current)
  const emitter = useMemo(() => getOrCreateEmitter(key), [key])

  const setValueAndNotify = useCallback(
    (value: T | undefined) => {
      emitter.emit(key, value)
    },
    [emitter, key],
  )

  useEffect(() => {
    const onChange = (value: T | undefined) => {
      setValue(value)
    }
    emitter.on(key, onChange)
    return () => {
      emitter.off(key, onChange)
    }
  }, [key, emitter, setValue])

  return [value ?? defaultValueRef.current, setValueAndNotify]
}
