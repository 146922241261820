import { Accordion, AccordionProps, SxProps, Theme } from '@mui/material'
import { flatSx } from './../lib/sx-props'

type Props = {} & AccordionProps
const rootSx: SxProps<Theme> = {
  boxShadow: 'none',
}

export function CLStyledAccordion(props: Props) {
  const { sx, children, ...rest } = props

  return (
    <Accordion sx={flatSx(rootSx, sx)} {...rest} disableGutters>
      {children}
    </Accordion>
  )
}
