import { ApiHelper, ApiHelperWithData, BaseRequest, splitParams } from '@clover/js-api-common'
import { Cafeteria, PagerData } from '../model'

/**
 * 카페테리아 영수증 리스트(Cafeteria) API
 */
export class CafeteriatApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * Cafeteria 게시물 정보 등록
   */
  create = (
    params: {
      money?: number
      category?: string
      item?: string
      days?: Date
    } & BaseRequest,
  ): Promise<{ instCnt: number }> => {
    const url = '/api/cafeteria/create'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 사용자별 카페테리아 영수증 목록 조회
   */
  list = (
    params: {
      userId: number
    } & BaseRequest,
  ): Promise<{ cafeteriaList: Cafeteria[] }> => {
    const url = '/apis/cafeteria/list'
    return this.withData.post(url, ...splitParams(params))
  }
}
