import { Box, SxProps, Theme, Typography, alpha } from '@mui/material'
import clsx from 'clsx'
import React from 'react'
import { flatSx } from './../lib/sx-props'

const rootSx: SxProps<Theme> = {
  flex: 1,
  display: 'flex',
  alignItems: 'stretch',
  my: 0.2,
  '& .CLDocLabelAny-titleBox': {
    minWidth: 130,
    backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.15),
    display: 'flex',
    alignItems: 'center',
    pl: 1,
  },

  '& .CLDocLabelAny-children': {
    ml: 0.5,
    mr: 0,
    py: 0,
    flex: 1,
  },
  '& .PrivateSwitchBase-input': {
    py: 0,
  },
}

type Props = {
  sx?: SxProps
  className?: string
  title: string
  children?: React.ReactNode
  readOnly?: boolean
  required?: boolean
}

export const CLDocLabelAny = React.forwardRef<HTMLDivElement, Props>(
  (props, ref): JSX.Element | null => {
    const { sx, className, title, children, readOnly, required } = props

    return (
      <Box
        className={clsx('CLDocLabelAny-root', className)}
        sx={flatSx(rootSx, sx)} //
        ref={ref}
      >
        <Box className="CLDocLabelAny-titleBox">
          <Typography fontSize="0.75rem">{title}</Typography>
          {required && <Typography ml={1}>*</Typography>}
        </Box>
        <Box
          className="CLDocLabelAny-children"
          sx={
            readOnly
              ? {
                  '& .MuiInputBase-root': { backgroundColor: '#f5f5f5' },

                  '& .MuiInputBase-input': {
                    WebkitTextFillColor: '#666',
                  },
                }
              : {}
          }
        >
          {children}
        </Box>
      </Box>
    )
  },
)

CLDocLabelAny.displayName = 'CLDocLabelAny'
