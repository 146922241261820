import * as React from 'react'
export const FolderCloseIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={28}
    height={14}
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.01367 12C1.60117 12 1.24817 11.8533 0.954672 11.5597C0.660672 11.2657 0.513672 10.9125 0.513672 10.5V1.5C0.513672 1.0875 0.660672 0.7345 0.954672 0.441C1.24817 0.147 1.60117 0 2.01367 0H5.89492C6.09492 0 6.28567 0.0375001 6.46717 0.1125C6.64817 0.1875 6.80742 0.29375 6.94492 0.43125L8.01367 1.5H14.0137C14.4262 1.5 14.7794 1.647 15.0734 1.941C15.3669 2.2345 15.5137 2.5875 15.5137 3V10.5C15.5137 10.9125 15.3669 11.2657 15.0734 11.5597C14.7794 11.8533 14.4262 12 14.0137 12H2.01367Z"
      fill="url(#paint0_linear_466_6130)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_466_6130"
        x1={3.3919}
        y1={-3.30853}
        x2={10.4212}
        y2={9.05332}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F6D365" />
        <stop offset={1} stopColor="#FDA085" />
      </linearGradient>
    </defs>
  </svg>
)
