import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined'
import { TreeView, TreeViewProps } from '@mui/lab'
import { IconButton, Stack, SxProps, Theme, Tooltip, Typography } from '@mui/material'
import Image from 'next/image'
import { flatSx } from './../lib/sx-props'
type Props = {
  collapseClick?: () => void
  treeTitle?: string
} & TreeViewProps

const rootSx: SxProps<Theme> = {
  '& .MuiTreeItem-iconContainer': {
    width: 18,
  },
}

export function CLStyledTreeView(props: Props) {
  const { treeTitle, collapseClick, children, sx, ...rest } = props

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        {treeTitle && (
          <Typography sx={{ m: 1 }} variant="subtitle2">
            {treeTitle}
          </Typography>
        )}
        {collapseClick && (
          <Tooltip arrow title="전체 닫기">
            <IconButton
              sx={{ mr: 1, '& .MuiSvgIcon-root': { width: '1.2rem' } }}
              size="small"
              onClick={collapseClick}
            >
              <IndeterminateCheckBoxOutlinedIcon />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
      <TreeView
        defaultCollapseIcon={
          <Image width={15} height={11} src="/images/rullIcon/folder-open.png" alt="" />
        }
        defaultExpandIcon={
          <Image width={15} height={11} src="/images/rullIcon/folder-close.png" alt="" />
        }
        sx={flatSx(rootSx, sx)}
        {...rest}
      >
        {children}
      </TreeView>
    </>
  )
}
