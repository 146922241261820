import { createTheme } from '@mui/material/styles'
import { overrides } from './overrides'
import { typography } from './typography'
import { palette } from './palette'

// Create a theme instance.
const theme = createTheme({
  palette,
  typography,
  components: {
    ...overrides,
  },
})

export default theme
