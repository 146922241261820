import { ApiHelper, ApiHelperWithData, BaseRequest, splitParams } from '@clover/js-api-common'
import { CLRoleUser } from '..'
import { CLPriv } from './../model/CLPriv'
import { CLRole } from './../model/CLRole'

/**
 * clover framework ROLE USER API
 */
export class CLRoleUserApi {
  private withData: ApiHelperWithData

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 사용자에게 부여된 롤 목록 조회
   */
  roleList = (
    params: {
      userId: number
    } & BaseRequest,
  ): Promise<{
    grantedRoleIds: string[]
    roleList: CLRole[]
  }> => {
    const url = '/apis/cloverframework/role-user/role-list'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * 사용자에게 부여된 권한 목록 조회
   */
  privList = (
    params: {
      userId: number
    } & BaseRequest,
  ): Promise<{
    grantedPrivIds: string[]
    privList: CLPriv[]
  }> => {
    const url = '/apis/cloverframework/role-user/priv-list'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * 사용자에게 권한 부여 - grant
   */
  grantPriv = async (
    params: {
      userId: number
      privId: string
    } & BaseRequest,
  ): Promise<void> => {
    const url = '/apis/cloverframework/role-user/grant-priv'
    await this.helper.post(url, ...splitParams(params))
  }

  /**
   * 사용자에게 권한 회수 - revoke
   */
  revokePriv = async (
    params: {
      userId: number
      privId: string
    } & BaseRequest,
  ): Promise<void> => {
    const url = '/apis/cloverframework/role-user/revoke-priv'
    await this.helper.post(url, ...splitParams(params))
  }

  /**
   * 사용자에게 롤 부여 - grant
   */
  grantRole = async (
    params: {
      userId: number
      roleId: string
    } & BaseRequest,
  ): Promise<void> => {
    const url = '/apis/cloverframework/role-user/grant-role'
    await this.helper.post(url, ...splitParams(params))
  }

  /**
   * 사용자에게 롤 회수 - revoke
   */
  revokeRole = async (
    params: {
      userId: number
      roleId: string
    } & BaseRequest,
  ): Promise<void> => {
    const url = '/apis/cloverframework/role-user/revoke-role'
    await this.helper.post(url, ...splitParams(params))
  }

  /**
   * 특정 롤의 사용자 목록 조회
   */
  userListByRole = (
    params: {
      roleId: string
    } & BaseRequest,
  ): Promise<{ userList: CLRoleUser[] }> => {
    const url = '/apis/cloverframework/role-user/role-granted-users'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * 특정 권한의 사용자 목록 조회
   */
  userListByPriv = (
    params: {
      privId: string
    } & BaseRequest,
  ): Promise<{ userList: CLRoleUser[] }> => {
    const url = '/apis/cloverframework/role-user/priv-granted-users'
    return this.withData.post(url, ...splitParams(params))
  }
}
