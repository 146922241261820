import { blue, red } from '@mui/material/colors'
import { PaletteOptions } from '@mui/material/styles'

export const palette: PaletteOptions = {
  primary: {
    main: '#556cd6',
  },
  secondary: {
    main: '#19857b',
  },
  error: {
    main: red.A400,
  },
  custom: {
    main: blue.A400,
  },
}
