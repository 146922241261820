import { AccordionDetails, AccordionDetailsProps, SxProps, Theme } from '@mui/material'
import { flatSx } from './../lib/sx-props'
type Props = {} & AccordionDetailsProps

const rootSx: SxProps<Theme> = {
  p: 0,
}

export function CLStyledAccordionDetails(props: Props) {
  const { sx, children, ...rest } = props

  return (
    <AccordionDetails sx={flatSx(rootSx, sx)} {...rest}>
      {children}
    </AccordionDetails>
  )
}
