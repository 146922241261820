import { ApiHelper, ApiHelperWithData, BaseRequest, splitParams } from '@clover/js-api-common'
import { PagerData, VillaReservation } from '../model'

/**
 *  별장 예약 API
 */
export class VillaReservationApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   *빌라 예약 신청 현황 목록 조회
   */
  list = (
    params: {
      srtDt?: String
      userNm?: string
      isWeekDay?: string
      rowsPerPage: number
      pageNumber: number
      lgonId?: string
    } & BaseRequest,
  ): Promise<{ villaReservationList: PagerData<VillaReservation> }> => {
    //controller와 일치시키기
    const url = '/apis/villa-reservation/list'
    return this.withData.postJson(url, ...splitParams(params))
  }
  /**
   * 별장 현황 단건 조회
   */
  info = (
    params: {
      seqId: number
    } & BaseRequest,
  ): Promise<{
    villaReservation: VillaReservation
  }> => {
    const url = '/apis/villa-reservation/info'
    return this.withData.post(url, ...splitParams(params))
  }

  create = (
    params: {
      userId: number
      endDt: Date
      lgonId: string
      srtDt: Date
      isWeekDay: string
      userNm: string
    } & BaseRequest,
  ): Promise<{
    instCnt: number
  }> => {
    const url = '/api/villa-reservation/create'
    return this.withData.postJson(url, ...splitParams(params))
  }
}
