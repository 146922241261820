import { ApiHelper, ApiHelperWithData, BaseRequest, splitParams } from '@clover/js-api-common'
import { CLJob } from '../model'

/**
 * clover framework 잡 API
 */
export class CLJobApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * JOB 목록 조회
   * 데이터가 몇개 안되므로 전체 조회
   */
  list = (
    params: BaseRequest,
  ): Promise<{
    jobList: CLJob[]
  }> => {
    const url = '/p/cloverframework/job/list'
    return this.withData.post(url, ...splitParams(params))
  }
}
