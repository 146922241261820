import { LoginFormDialogProps } from '@/dialogs/LoginFormDialog'
import LoginFormDialog from '@/dialogs/LoginFormDialog/LoginFormDialog'
import { useCallback, useEffect, useState } from 'react'

const CLICK_TARGET = '.LoginButton'

type DialogId = 'LoginFormDialog'
/**
 * className LoginButton을 붙혀주면 로그인팝업 시전  !!!공부해야할 가치가 있음
 */
export default function LoginDialogOpenEventHandler() {
  const [dialogId, setDialogId] = useState<DialogId>()
  const [loginFormDialogProps, setLoginFormDialogProps] = useState<LoginFormDialogProps>()

  const closeDialog = () => {
    setDialogId(undefined)
  }

  const openLoginFormDialog = useCallback(() => {
    setDialogId('LoginFormDialog')
    setLoginFormDialogProps({
      open: true,
      onClose: closeDialog,
    })
  }, [])

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (!event.target) return
      const tagName = (event.target as any)['tagName']
      if (!tagName) return
      const elem = event.target as HTMLElement

      // elem에 MuiButton-root 클래스가 포함되어 있는지 체크
      elem.classList.contains('MuiButton-root')

      // MuiButton-root
      console.log(event.target)
      // if (elem.classList.contains('LoginButton')) {
      //   openLoginFormDialog()
      // }

      // 1. 타겟이 .LoginButton에 매치되면 다이얼로그 띄우기
      // 2. 또는, 타겟의 부모 요소가 .LoginButton에 매치되면 다이얼로그 띄우기
      if (elem.matches(CLICK_TARGET) || elem.closest(CLICK_TARGET)) {
        openLoginFormDialog()
        return
      }
    }

    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [openLoginFormDialog])

  useEffect(() => {
    const handleEvent = (event: any) => {
      // const evt = event as CustomEvent
      console.log('LOGIN_DIALOG_OPEN', event)
      console.log('다이어로그 보이네', event)
      openLoginFormDialog()
    }

    window.addEventListener('LOGIN_DIALOG_OPEN', handleEvent)
    return () => {}
  }, [openLoginFormDialog])

  if (dialogId === 'LoginFormDialog' && loginFormDialogProps) {
    return <LoginFormDialog {...loginFormDialogProps} />
  }

  return null
}
