import * as icons from '@mui/icons-material'

type IconNames = keyof typeof icons

export const DEFAULT_ICON_LIST: IconNames[] = [
  'Description',
  'FilePresent',
  'FolderOutlined',
  'HelpOutlineOutlined',
  'Home',
  'ManageSearch',
  'Monitor',
  'NoteAlt',
  'Settings',
  'ViewQuilt',
  'Assignment',
  'Adb',
  'AirportShuttle',
  'AutoAwesomeMosaic',
  'Autorenew',
  'Call',
  'Cloud',
  'Diamond',
  'Drafts',
  'Email',
  'FavoriteBorder',
  'GppGood',
  'HealthAndSafety',
  'Instagram',
  'LaptopChromebook',
  'LightbulbCircle',
  'LocalPolice',
  'Phone',
  'PowerSettingsNew',
  'QuestionMark',
  'Search',
  'Telegram',
  'ThumbUp',
  'ZoomIn',
  'Work',
]
