import { useRef } from 'react'
import { useCopyToClipboard } from 'react-use'
import { useTimeoutData } from './../hooks/useTimeoutData'
import { BbsButton, BbsButtonProps } from './BbsButton'

type Props = {
  textProviderFunc: () => string | undefined | null
} & BbsButtonProps

export function BbsClipboardButton(props: Props) {
  const { textProviderFunc, disabled, ...restProps } = props
  const textProviderFn = useRef<any>()
  textProviderFn.current = textProviderFunc
  const [, copyToClipboard] = useCopyToClipboard()
  const [visibleCopied, setVisibleCopied] = useTimeoutData<boolean>(1000)

  const handleClickCopyButton = () => {
    const text = textProviderFn.current?.()
    if (text && text.length > 0) {
      copyToClipboard(text)
      setVisibleCopied(true)
    }
  }

  const visible = visibleCopied ?? false
  return (
    <BbsButton
      disabled={disabled || visible}
      variant={visible ? 'text' : 'outlined'}
      style={{ color: visible ? '#888' : undefined }}
      onClick={handleClickCopyButton}
      text={visible ? 'Copied' : '주소복사'}
      {...restProps}
    />
  )
}
