import { ApiHelper, ApiHelperWithData, BaseRequest, splitParams } from '@clover/js-api-common'
import { CLRole, CLRolePageDetail } from '../model'

/**
 * clover framework ROLE API
 */
export class CLRoleApi {
  private withData: ApiHelperWithData

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 롤 페이지 권한 목록 조회
   */
  rolePages = (
    params: {
      roleId: string
    } & BaseRequest,
  ): Promise<{
    rolePageList: CLRolePageDetail[]
  }> => {
    const url = '/apis/cloverframework/role/pages'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * 롤 목록 조회
   */
  roles = (
    params: BaseRequest,
  ): Promise<{
    roleList: CLRole[]
  }> => {
    const url = '/apis/cloverframework/role/list'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 롤 정보 단건조회
   */
  roleInfo = (
    params: {
      roleId: string
    } & BaseRequest,
  ): Promise<{
    role: CLRole
  }> => {
    const url = '/apis/cloverframework/role/info'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * 롤 정보 존재 여부 체크
   * 신규 등록할 때 체크
   */
  existsRoleById = async (
    params: {
      roleId: string
    } & BaseRequest,
  ): Promise<boolean> => {
    try {
      await this.roleInfo(params)
      return true
    } catch (err: any) {
      if (err['errorCode'] === 'E1_NO_SUCH_DATA') {
        return false
      }
      throw err
    }
  }

  /**
   * 롤 정보 신규 등록
   */
  createRole = (
    params: {
      roleId: string
      roleNm: string
      dtlExpl?: string
    } & BaseRequest,
  ): Promise<{
    role: CLRole
  }> => {
    const url = '/apis/cloverframework/role/create-role'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 롤 정보 수정
   */
  updateRole = (
    params: {
      roleId: string
      roleNm: string
      dtlExpl?: string
    } & BaseRequest,
  ): Promise<{
    role: CLRole
  }> => {
    const url = '/apis/cloverframework/role/update-role'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 롤 정보 삭제
   */
  deleteRole = async (
    params: {
      roleId: string
    } & BaseRequest,
  ) => {
    const url = '/apis/cloverframework/role/delete'
    await this.helper.post(url, ...splitParams(params))
  }

  /**
   * 롤 페이지 권한 저장
   */
  saveRolePage = async (
    params: {
      roleId: string
      pageId: number
      c: boolean
      r: boolean
      u: boolean
      d: boolean
    } & BaseRequest,
  ): Promise<void> => {
    const url = '/apis/cloverframework/role/update-role-page'
    await this.helper.postJson(url, ...splitParams(params))
  }

  /**
   * 주어진 롤에 모든 페이지의 권한을 저장한다.
   */
  grantAllRolePage = async (
    params: {
      roleId: string
    } & BaseRequest,
  ) => {
    const url = '/apis/cloverframework/role/grant-all-role-page'
    await this.helper.post(url, ...splitParams(params))
  }

  /**
   * 주어진 롤에 모든 페이지의 권한을 제거한다.
   */
  revokeAllRolePage = async (
    params: {
      roleId: string
    } & BaseRequest,
  ) => {
    const url = '/apis/cloverframework/role/revoke-all-role-page'
    await this.helper.post(url, ...splitParams(params))
  }
}
