import * as React from 'react'
export const FolderOpenIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={28}
    height={14}
    viewBox="0 0 17 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.01367 12C1.60117 12 1.24817 11.8533 0.954672 11.5598C0.660672 11.2658 0.513672 10.9125 0.513672 10.5V1.5C0.513672 1.0875 0.660672 0.7345 0.954672 0.441C1.24817 0.147 1.60117 0 2.01367 0H5.89492C6.09492 0 6.28567 0.0375001 6.46717 0.1125C6.64817 0.1875 6.80742 0.29375 6.94492 0.43125L8.01367 1.5H14.0137C14.4262 1.5 14.7794 1.647 15.0734 1.941C15.3669 2.2345 15.5137 2.5875 15.5137 3H3.51367C3.10117 3 2.74817 3.14675 2.45467 3.44025C2.16067 3.73425 2.01367 4.0875 2.01367 4.5V10.5L3.49492 5.56875C3.59492 5.24375 3.77942 4.98425 4.04842 4.79025C4.31692 4.59675 4.61367 4.5 4.93867 4.5H14.6137C15.1262 4.5 15.5294 4.703 15.8234 5.109C16.1169 5.5155 16.1949 5.95625 16.0574 6.43125L14.7074 10.9313C14.6074 11.2563 14.4232 11.5158 14.1547 11.7098C13.8857 11.9033 13.5887 12 13.2637 12H2.01367Z"
      fill="url(#paint0_linear_456_6023)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_456_6023"
        x1={3.50886}
        y1={-3.30853}
        x2={10.3924}
        y2={9.28902}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F6D365" />
        <stop offset={1} stopColor="#FDA085" />
      </linearGradient>
    </defs>
  </svg>
)
