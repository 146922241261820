import { Box, Select, SelectProps, SxProps, Theme, Typography, alpha } from '@mui/material'
import clsx from 'clsx'
import React from 'react'
import { flatSx } from './../lib/sx-props'

const rootSx: SxProps<Theme> = {
  flex: 1,
  display: 'flex',
  alignItems: 'stretch',
  my: 0.2,
  '& .CLDocLabelSelect-titleBox': {
    minWidth: 130,
    backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.15),
    display: 'flex',
    alignItems: 'center',
    pl: 1,
  },
  '& .MuiInputBase-root': {
    fontSize: '0.7rem',
    borderRadius: 0,
    minWidth: '100%',
    '& .MuiInputBase-input': {
      p: 0.8,
      width: '100%',
      minWidth: 50,
    },
  },
  '& .CLDocLabelSelect-input': {
    ml: 0.5,
    mr: 0,
    flex: 1,
    py: 0,
  },
}

type Props = {
  title: string
  arr?: string[]
  listFontSize?: string
  readOnly?: boolean
  children?: React.ReactNode
  required?: boolean
} & SelectProps

export const CLDocLabelSelect: React.FC<Props> = (props) => {
  const {
    arr,
    title,
    sx,
    className,
    readOnly,
    fullWidth = true,
    listFontSize = '0.75rem',
    children,
    required,
    ...rest
  } = props

  return (
    <Box sx={flatSx(rootSx, sx)} className={clsx('CLDocLabelSelect-root', className)}>
      <Box className="CLDocLabelSelect-titleBox">
        <Typography fontSize="0.75rem">{title}</Typography>
        {required && <Typography ml={1}>*</Typography>}
      </Box>
      <Box className="CLDocLabelSelect-input">
        <Select
          disabled={readOnly}
          sx={
            readOnly
              ? {
                  '& .MuiSelect-select': {
                    backgroundColor: '#f5f5f5',
                    WebkitTextFillColor: '#666',
                  },
                }
              : {}
          }
          fullWidth={fullWidth}
          size="small"
          {...rest}
        >
          {children}
        </Select>
      </Box>
    </Box>
  )
}

CLDocLabelSelect.displayName = 'CLDocLabelSelect'
