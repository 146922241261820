import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { AccordionSummary, AccordionSummaryProps, SxProps, Theme } from '@mui/material'
import { flatSx } from './../lib/sx-props'
type Props = {} & AccordionSummaryProps

const rootSx: SxProps<Theme> = {
  borderBottom: '1px solid #d5d5d5',
  '&:hover': {
    backgroundColor: '#f5f5f5',
  },
  '&.Mui-expanded': {
    minHeight: 29,
    borderBottom: 0,
  },

  '& .MuiAccordionSummary-content': {
    my: 0,
  },
}

export function CLStyledAccordionSummary(props: Props) {
  const { sx, children, ...rest } = props

  return (
    <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={flatSx(rootSx, sx)} {...rest}>
      {children}
    </AccordionSummary>
  )
}
