import { TreeItem, TreeItemProps } from '@mui/lab'
import { Box, SxProps, Theme, alpha } from '@mui/material'
import React from 'react'
import { flatSx } from './../lib/sx-props'
type Props = {
  labelIcon?: React.ReactNode
} & TreeItemProps

const rootSx: SxProps<Theme> = {
  '& .MuiTreeItem-content': {
    py: 1,
  },

  '& .Mui-selected': {
    backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.1),
  },
  '& .Mui-selected .Mui-focused': {
    backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.1),
  },
  '& .MuiTreeItem-label': {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}

export const CLStyledTreeItem = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { labelIcon, children, sx, ...rest } = props

  return (
    <Box ref={ref} sx={flatSx(rootSx, sx)}>
      {labelIcon ? (
        <TreeItem icon={labelIcon} {...rest}>
          {children}
        </TreeItem>
      ) : (
        <TreeItem sx={flatSx(rootSx, sx)} {...rest}>
          {children}
        </TreeItem>
      )}
    </Box>
  )
})

CLStyledTreeItem.displayName = 'CLStyledTreeItem'
