import { Tab, TabProps, Tabs, TabsProps } from '@mui/material'
import { styled } from '@mui/material/styles'

export type TabLabelValues = {
  tabLabel?: string
  tabValue?: string
}

type Props = {
  tabLabelValues: TabLabelValues[]
} & TabsProps

export function CLStyleTabsByTab(props: Props) {
  const { tabLabelValues, sx, ...rest } = props

  return (
    <StyledTabs sx={sx} {...rest} className="CLStyleTabsByTab-root">
      {tabLabelValues.map(({ tabLabel, tabValue }) => (
        <StyledTab key={tabValue} label={tabLabel} value={tabValue} />
      ))}
    </StyledTabs>
  )
}

const StyledTab = styled(Tab)<TabProps>(({ theme }) => {
  return {
    marginRight: theme.spacing(0.5),
    fontSize: '0.9rem',
    border: '1px solid #e0e4ee',
    backgroundColor: '#e1e1e180',
    minHeight: 42,
    maxHeight: 42,
    '&.Mui-selected': {
      color: theme.palette.primary.dark,
      backgroundColor: '#fff',
    },
  }
})

const StyledTabs = styled(Tabs)<TabsProps>(({ theme }) => {
  return {
    minHeight: 42,
    maxHeight: 42,
    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.primary.main,
    },
  }
})
