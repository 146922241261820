import ClearIcon from '@mui/icons-material/Clear'
import { Box, FormControl, Stack, TextField, Tooltip } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import koDateLocale from 'date-fns/locale/ko'

const DATE_LOCALE = koDateLocale

type Props = {
  selectDate?: Date | undefined
  onDateChange: (pickedDate: Date | null) => void
  label?: string
  toolbarTitle?: string
  textFieldSize?: string
  toolTipTitle?: string
}

export function DatePickerForm(props: Props) {
  const { selectDate, onDateChange, label, toolbarTitle, toolTipTitle = '초기화' } = props
  return (
    <Stack direction="row" alignItems="flex-end">
      <FormControl>
        <Box>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={DATE_LOCALE}>
            <DatePicker
              label={label ?? ''}
              inputFormat="yyyy/MM/dd"
              value={selectDate ?? null}
              ignoreInvalidInputs
              toolbarTitle={toolbarTitle ?? ''}
              onChange={onDateChange}
              renderInput={(params) => <TextField margin="none" size="small" {...params} />}
              closeOnSelect
              // showToolbar
            />
          </LocalizationProvider>
        </Box>
      </FormControl>
      {selectDate && (
        <Box>
          <Tooltip title={toolTipTitle}>
            <ClearIcon
              fontSize="small"
              sx={{
                ml: 0.5,
                cursor: 'pointer',
                '&:hover': {
                  color: '#F24C4C',
                },
              }}
              onClick={() => onDateChange(null)}
            />
          </Tooltip>
        </Box>
      )}
    </Stack>
  )
}
